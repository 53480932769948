@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
/*font-family: 'Poppins', sans-serif;*/

:root {

    /* COLORS */
    --prime-blue: var(--cyan-400) !important;
    /* FONTS */

    /* FAMILY */
    --font-pop: 'Poppins', sans-serif;

    /* SIZE */
    --font-size-s: 12;

    /* COLOR */
    --font-color-w: #fff;
    --font-color-b: #000;
}

body,
html {
    margin: 0;
    padding: 0;
    height: 100% !important;
    width: 100% !important;
    font-family: var(--font-pop);
    font-size: var(--font-size-s);
}

.p-sidebar-right .p-sidebar.employee-p-sidebar {
    width: 800px !important;
}
.navbar-container{
    display: flex;
    justify-content: space-between;
}


.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.055);
    width: 100%;
    height: 100vh;
    display: flex;

}
.p-progress-spinner{
    align-self: center;

}


.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.2) !important;
}
