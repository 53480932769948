/* *************LOGIN */
.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 100vh;
}

/******************************************** LAYOUT MAIN CONTAINER*/
.layout-main-container {
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    /* justify-content: space-between; */
    /* padding: 7rem 2rem 2rem 4rem; */
    transition: margin-left .2s;
    background-color: var(--body-color);
    /* padding: 0 1rem; */
    position: relative !important;
}

.layout-main {
    /* margin: 10px 0 110px 0; */
    margin: 0px 0 110px 0;
    padding-top: 10px !important;
    /* padding-top: 60px !important; */
    /* padding-bottom: 100px !important; */
    /* margin-top: -55px; */
    /* padding-top: 80px; */
    /* background-color: var(--surface-300); */
    /* background-image: url('./assets/svg/wave.svg'); */
    background-repeat: no-repeat !important;
}

.layout-wrapper.layout-static .layout-main-container {
    margin-left: 300px;
}

.layout-sidebar-inactive {
    transform: translateX(-100%);
    left: 0px;
}

/******************************************** SIDEBAR */

.layout-sidebar {
    position: fixed;
    width: 300px;
    /* height: calc(100vh - 6.5rem); */
    height: calc(100vh);
    z-index: 999;
    overflow-y: scroll !important;
    /* top: 6rem; */
    /* left: 2rem; */
    transition: transform .2s, left .2s;
    /* background-color: var(--accent-color) !important; */
    /* border-radius: 12px; */
    padding: 0.5rem 1.5rem;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .02), 0 0 2px rgba(0, 0, 0, .05), 0 1px 4px rgba(0, 0, 0, .08) !important;
    box-shadow: 1px 0px 24px -10px rgba(0, 0, 0, 0.63) !important;
    -webkit-box-shadow: 1px 0px 24px -10px rgba(0, 0, 0, 0.63) !important;
    -moz-box-shadow: 1px 0px 24px -10px rgba(0, 0, 0, 0.63) !important;
    transition: .2s ease-in-out;
    overflow: hidden;
    padding-bottom: 50px !important;
}

/******************************************** SIDEBAR LINK */

a {
    text-decoration: none !important;
    color: var(--font-color-b);
    transition: var(--trans-delay1);
}

.active-link {
    align-items: center;
    padding: 7px 0 7px 20px;
    gap: 5px;
    transition: .2s ease-in-out;
    /* background-color: var(--primary-color); */
    border-radius: 10px;
    color: var(--font-color-w);
    /* background-color: var(--accent-color); */
    display: flex;
    /* background-image: linear-gradient(135deg, #FD6E6A 10%, #FFC600 100%) !important; */

    /* background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%) !important; */
    /* colorfulPeach */
    /* background-image: linear-gradient(to right, #ed6ea0 0%, #ec8c69 100%) !important; */
    background-color: var(--prime-blue);
}

.normal-link {
    align-items: center;
    /* color: var(--accent-color); */
    padding: 7px 0 7px 10px;
    gap: 5px;
    transition: .2s ease-in-out;
    display: flex;
}

.normal-link:hover {
    background-color: var(--surface-400);
    color: var(--pr-body-font-active);
    border-radius: 10px;
}

@media (max-width: 600px) {
    .layout-wrapper.layout-static .layout-main-container {
        margin-left: 0px;
    }

    .layout-main-container {
        margin-left: 0px !important;

    }
}

@media (max-width: 992px) {
    .layout-wrapper.layout-static .layout-main-container {
        margin-left: 0px;
    }

    .layout-main-container {
        margin-left: 0px !important;
    }

    .layout-main-container-emp {
        margin-left: 0px !important;
        /* transition: 0s ease; */
    }
}

/******************************************** SIDEBAR ICONS */

.my-icons {
    background-color: var(--primary-color) !important;
    /* padding: 4px 7px !important; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    color: var(--font-color-b);
    border-radius: 6px;
    /* background: #036475;
    box-shadow: 3px 3px 6px #01282f,
        -3px -3px 6px #05a0bb !important; */
    background-color: #fff !important;
}

/******************************************** SCROLLS */

.p-sidebar-content::-webkit-scrollbar {
    width: 0px !important;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /* background-color: var(--accent-color) !important; */
}

::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 10px !important;

}

/******************************************** CARD */
.card {
    /* background: var(--primary-color); */
    /* background-color: #e8e8e8; */
    /* border: 1px solid var(--surface-border); */
    padding: 1rem;
    margin-bottom: .5rem;
    border-radius: 0 0 8px 8px;
    border-top: 3px solid var(--accent-color);
    transition: .2s ease-in-out;
    position: relative;
}